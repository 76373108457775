<template>
  <div v-if="hot && datasets.length > 0" class="mb-20 txt-black">
    <span class="txt-bold mr-4">{{ $t('components.labels.dataSets') }}</span>
    <a-tag v-for="dataset in splitDatasets.first" :key="dataset" :closable="!isSupervisor" @close="(e) => deleteDataSet(e, dataset)">{{ dataset }}</a-tag>
    <span v-if="splitDatasets.rest.length > 0">{{ $t('values.and') }} <a-button type="link" class="txt-orange pl-0 pr-0" @click="expand = !expand">{{ splitDatasets.rest.length }} {{ $t('values.more') }}<a-icon type="down" class="txt-10 dataset-expand" :class="{rotate: expand}"/></a-button></span>
    <div v-if="expand">
      <a-tag v-for="dataset in splitDatasets.rest" :key="dataset" closable class="ml-5" @close="(e) => deleteDataSet(e, dataset)">{{ dataset }}</a-tag>
    </div>
  </div>
</template>

<script>
import uniq from 'lodash/uniq';
import { mapState } from 'vuex';

export default {
  name: 'FilterButton',
  props: {
    hot: {
      type: Object,
      required: false,
      default: () => undefined
    },
    sourceColumn: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      expand: false
    };
  },
  computed: {
    ...mapState({
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    datasets() {
      const sourcesToFilter = ['smart collect', 'manually entered'];
      return uniq(this.hot.getDataAtCol(this.sourceColumn)).filter(row => row).filter(row => !sourcesToFilter.includes(row.toLowerCase()));
    },
    splitDatasets() {
      const [first, second, third, ...rest] = this.datasets;
      return {
        first: [first, second, third].filter(row => row),
        rest
      };
    }
  },
  methods: {
    deleteDataSet(e, dataset) {
      e.preventDefault();
      this.$emit('delete-data-set', dataset);
    }
  }
};
</script>

<style lang="scss" scoped>
  .dataset-expand {
    transition: transform 0.3s ease-in-out;
  }

  .rotate {
    transform: rotate(180deg);
  }
</style>
